import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Imagewithtext from "../components/supplyheatlhcheck/Imagewithtext";
import Welcome from "../components/supplyheatlhcheck/Welcome";
function SupplyHealth() {
  return (
    <div>
      <Helmet>
        <title>Supply Health Check</title>
        <meta name="description" content="" />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <section>
          <Welcome />
          <Imagewithtext />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default SupplyHealth;
