import React, { useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import imageUrl1 from "../../assets/supply_health/SCM.png";
import imageUrl2 from "../../assets/supply_health/Warehousing.png";
import imageUrl3 from "../../assets/supply_health/Procure to pay.png";
import imageUrl4 from "../../assets/supply_health/Transport Management.png";
import imageUrl5 from "../../assets/supply_health/Logistics Planning.png";

const Imagetext = () => {
  const slides = [
    {
      image: imageUrl1,
      heading: "SCM",
      text: "Our SCM tool offers a comprehensive overview of your organization's supply chain health, enabling you to make informed decisions and optimize your operations. By tracking metrics related to People, Planet, Profit, Protection, and Prospects, you can identify areas for improvement, assess risks, and ensure your supply chain is aligned with your overall business objectives.",
    },
    {
      image: imageUrl2,
      heading: "Warehousing",
      text: "Our SCM tool provides real-time insights into your warehousing operations, allowing you to monitor key performance indicators (KPIs) related to Systems, Process, Safety, Lean and Quality, and Environmental Sustainability. This data-driven approach enables you to identify bottlenecks, improve efficiency, and ensure compliance with industry standards.",
    },
    {
      image: imageUrl3,
      heading: "Procure-to-Pay",
      text: "Our SCM tool offers visibility into your procure-to-pay processes, enabling you to streamline procurement, optimize spend, and improve supplier relationships. By tracking metrics related to Systems, Process, Analytics, S&OP, and Environmental Sustainability, you can identify opportunities for cost savings, risk mitigation, and sustainable sourcing.",
    },
    {
      image: imageUrl4,
      heading: "Transport Management",
      text: "Our SCM tool provides comprehensive visibility into your transportation operations, enabling you to optimize routes, reduce costs, and improve delivery performance. By tracking metrics related to Systems, Fleet Management, Maintenance and Repairs, Cost, and Environmental Sustainability, you can identify opportunities for efficiency gains, cost savings, and sustainability improvements.",
    },
    {
      image: imageUrl5,
      heading: "Logistics Planning",
      text: "Our SCM tool provides insights into your logistics planning processes, enabling you to optimize transportation scheduling, supplier collaboration, and overall supply chain efficiency. By tracking metrics related to Systems, Internal Processes, Transport Scheduling and Cost, Supplier Collaboration, and Environmental Sustainability, you can identify opportunities for improved planning, reduced costs, and enhanced customer service.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative flex flex-col space-y-6 py-12 items-center">
      <button
        onClick={prevSlide}
        className="hidden lg:block absolute left-5 top-1/2 transform -translate-y-1/2 text-[#278083] rounded-full p-2"
      >
        <FaArrowAltCircleLeft size={30} />
      </button>
      <button
        onClick={nextSlide}
        className="hidden lg:block absolute right-5 top-1/2 transform -translate-y-1/2 text-[#278083] rounded-full p-2"
      >
        <FaArrowAltCircleRight size={30} />
      </button>

      <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-12 max-w-[1310px]">
        <div className="lg:w-1/2">
          <img
            src={slides[currentSlide].image}
            alt={`slide-${currentSlide}`}
            className="w-full h-auto transition-transform duration-500 ease-in-out"
          />
        </div>
        <div className="lg:w-1/2 mx-4">
          <h2 className="text-2xl lg:text-3xl font-bold text-[#278083] mb-4">
            {slides[currentSlide].heading}
          </h2>
          <p className="text-lg lg:text-xl text-black leading-7">
            {slides[currentSlide].text}
          </p>
        </div>
      </div>

      {/* Centered Mobile Arrows (Visible on mobile and tablet screens) */}
      <div className="flex lg:hidden justify-center w-full mt-6 space-x-4">
        <button onClick={prevSlide} className="text-[#278083] rounded-full p-2">
          <FaArrowAltCircleLeft size={30} />
        </button>
        <button onClick={nextSlide} className="text-[#278083] rounded-full p-2">
          <FaArrowAltCircleRight size={30} />
        </button>
      </div>

      <a
        href="/Supply-Health-Check"
        className="mt-8 bg-[#278083] text-white py-3 px-6 rounded-full text-lg flex items-center"
      >
        Click Here To Start Your Free Scan
        <FaArrowAltCircleRight className="ml-2" />
      </a>
    </div>
  );
};

export default Imagetext;
