import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const SpiderWebChart = React.forwardRef(
  ({ departmentScores, selectedDepartment, onRender }, chartRef) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      const ctx = canvasRef.current?.getContext("2d");

      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy any existing chart instance
      }

      if (ctx && Object.keys(departmentScores).length > 0) {
        // Register plugin for background color
        const whiteBackgroundPlugin = {
          id: "whiteBackground",
          beforeDraw: (chart) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "white"; // Set background color to white
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          },
        };

        // Create a new Chart instance and assign it to chartRef
        chartRef.current = new Chart(ctx, {
          type: "radar",
          data: {
            labels: Object.keys(departmentScores),
            datasets: [
              {
                label: "Department Scores",
                data: Object.values(departmentScores),
                backgroundColor: "rgba(34, 202, 236, 0.2)",
                borderColor: "rgba(34, 202, 236, 1)",
                borderWidth: 2,
                pointBackgroundColor: "rgba(34, 202, 236, 1)",
              },
            ],
          },
          options: {
            scales: {
              r: {
                angleLines: { display: true },
                suggestedMin: 0,
                suggestedMax: 5,
              },
            },
            plugins: {
              legend: { display: true, position: "top" },
              whiteBackground: whiteBackgroundPlugin, // Add the plugin
            },
          },
          plugins: [whiteBackgroundPlugin],
        });

        // Notify that chart is ready
        if (onRender) {
          onRender(); // This should signal readiness to the parent component
        }
      }

      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [departmentScores, chartRef, onRender]);

    return (
      <>
        <h2 className="font-bold">{selectedDepartment} Spider Web Chart</h2>
        <canvas ref={canvasRef} width={400} height={400} />
      </>
    );
  }
);

export default SpiderWebChart;
