import React from "react";
import backgroundImage from "../../assets/Supply health check background Image.png"; // Update with your image file path

function Welcome() {
  return (
    <div className="relative h-[450px]">
      {" "}
      {/* Set a specific height here */}
      <img
        src={backgroundImage}
        alt="Background"
        className="background-image absolute inset-0 w-full h-full object-cover" // Make image cover the div height
      />
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
        <div className="welcome-content mx-8 max-w-[1080px] p-8 sm:p-4 bg-[#F8F8F8] bg-opacity-90 rounded-3xl">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl text-[#278083] font-bold mb-4">
            Supply Chain Health Check
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
