import React from "react";
import { Link } from "react-router-dom";
import logo from "../../src/assets/logoBV green 2.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="bg-white py-12">
      {/* Content Container */}
      <div
        className="container mx-auto px-4"
        style={{ maxWidth: "1275px", height: "auto" }}
      >
        <div className="flex flex-col items-center">
          {/* Logo */}
          <div className="w-full flex justify-center mb-8">
            <img src={logo} alt="Logo" className="h-[280px] w-[280px] mb-4" />
          </div>

          {/* Service Sections */}
          <div className="w-full flex flex-wrap justify-center gap-8 lg:gap-16">
            {/* SCM Services */}
            <div className="w-full sm:w-1/2 lg:w-auto mb-4 text-center">
              <h3 className="text-lg font-bold text-[#009782]">SCM Services</h3>
              <ul>
                <li className="mt-1">
                  <Link
                    to="/change-management"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Change Management
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/Lean-&-six-sigma"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Lean & Six Sigma Implementation
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/operational-excellence"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Operational Excellence
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/Supply-Chain-Optimization-Study"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Supply Chain Optimization
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/warehouse-design"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Warehouse Design
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/wms-implementation"
                    className="text-md text-[#898989] font-semibold"
                  >
                    WMS Implementation
                  </Link>
                </li>
              </ul>
            </div>

            {/* IT Support */}
            <div className="w-full sm:w-1/2 lg:w-auto mb-4 text-center">
              <h3 className="text-lg font-bold text-[#009782]">IT Support</h3>
              <ul>
                <li className="mt-1">
                  <Link
                    to="/automation"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Automation
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/big-data-management"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Big Data Management
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/interim-it-support"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Interim IT Support
                  </Link>
                </li>
              </ul>
            </div>

            {/* Oracle Cloud */}
            <div className="w-full sm:w-1/2 lg:w-auto mb-4 text-center">
              <h3 className="text-lg font-bold text-[#009782]">Oracle Cloud</h3>
              <ul>
                <li className="mt-1">
                  <Link
                    to="/cloud-transformation"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Cloud Transformation
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/Cloud-Data-&-AI"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Cloud Data & AI
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/cloud-managed-services"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Cloud Managed Services
                  </Link>
                </li>
              </ul>
            </div>

            {/* Digital Services */}
            <div className="w-full sm:w-1/2 lg:w-auto mb-4 text-center">
              <h3 className="text-lg font-bold text-[#009782]">
                Digital Services
              </h3>
              <ul>
                <li className="mt-1">
                  <Link
                    to="/Desinging"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Designing
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/Development"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Development
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="/Digital-Marketing"
                    className="text-md text-[#898989] font-semibold"
                  >
                    Digital Marketing
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Office Timing */}
          <div className="w-full text-center mt-8">
            <h3 className="text-lg font-bold text-[#009782]">
              OFFICE TIMINGS: MONDAY TO FRIDAY 9:00 - 18:00
            </h3>
          </div>

          {/* Contact Details */}
          <div className="flex flex-wrap justify-center mt-4 gap-x-4 md:gap-x-[180px] mb-4">
            <div className="flex items-center mr-4 text-[#009782]">
              <i className="fas fa-map-marker-alt mr-2"></i>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#898989",
                  textAlign: "center",
                }}
              >
                Mandenmakerstraat 100C, 3194DG, Hoogvliet Rotterdam
              </span>
            </div>
            <div className="flex items-center mr-4 text-[#009782]">
              <i className="fas fa-phone-alt mr-2"></i>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#898989",
                }}
              >
                +31 10 766 0786
              </span>
            </div>
            <div className="flex items-center text-[#009782]">
              <i className="far fa-envelope mr-2"></i>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#898989",
                }}
              >
                info@itsolutionsworldwide.com
              </span>
            </div>
          </div>

          {/* Navigation */}
          {/* Horizontal Line */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 1362 2"
            fill="none"
          >
            <path
              d="M1 1H1361"
              stroke="#898989"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="w-full md:w-auto mb-8 md:mb-0 flex flex-wrap items-center justify-center md:justify-start space-x-4 md:space-x-8">
            <Link
              to="/"
              className="text-[#278083] font-normal text-lg bg-white px-4 py-2 rounded-lg"
            >
              Home
            </Link>
            <span className="hidden md:inline">|</span>
            <Link
              to="/About-Us"
              className="text-[#278083] font-normal text-lg bg-white px-4 py-2 rounded-lg"
            >
              About Us
            </Link>
            <span className="hidden md:inline">|</span>
            <Link
              to="/Contact-Us"
              className="text-[#278083] font-normal text-lg bg-white px-4 py-2 rounded-lg"
            >
              Contact Us
            </Link>
            <span className="hidden md:inline">|</span>

            <Link
              to="/Career"
              className="text-[#278083] font-normal text-lg bg-white px-4 py-2 rounded-lg"
            >
              Career
            </Link>
          </div>

          {/* Social Media Links */}
          <div className="w-full md:w-auto flex items-center justify-center md:justify-end space-x-4 md:space-x-8 mt-4 md:mt-0">
            <a
              href="https://www.facebook.com/itsolutionsww/"
              className="text-[#999999]"
              target="_blank"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/itsolutionsworldwide/"
              className="text-[#999999]"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/ITSolutionsBV"
              className="text-[#999999]"
              target="_blank"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://nl.linkedin.com/company/it-solutions-worldwide-bv"
              className="text-[#999999]"
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </a>

            <a
              href="mailto:info@itsolutionsworldwide.com"
              className="text-[#999999]"
              target="_blank"
            >
              <i className="fas fa-envelope"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=31640754768"
              className="text-[#999999]"
              target="_blank"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>

        {/* Horizontal Line */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="2"
          viewBox="0 0 1362 2"
          fill="none"
        >
          <path
            d="M1 1H1361"
            stroke="#898989"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="flex justify-between mt-4">
          <p className="text-sm text-[#000]">
            Chamber of Commerce No. 72768916
          </p>
          <p className="text-sm text-[#000]">
            &copy; ITSolutionsWorldwide. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
